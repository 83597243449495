import React from 'react'
import customer from '../../Assets/customer.png';
import software from '../../Assets/software.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer.jsx'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import image1 from '../../Assets/services1.0.jpg'
import image3 from '../../Assets//web3.0.jpg'
import { ABOUT_PATH, CONTACT_PATH, CONTACT_US_PATH, } from '../../Utilities/Constants'

import image2 from '../../Assets/services2.0.jpg'
import { Link } from 'react-router-dom'
export default function () {
  return (
    <>
      <div className='bg-black'>

        <div
          className=' flex   justify-center flex-col  space-y-0 md:space-y-0 textcolor bg-whites'
        > 
          <div>
            <div className='flex flex-col justify-center text-center mt-2 sm:text-[12px] md:text-[16px]'>
              <h1 className='font-bold text-[42px] mt-28 textcolor'>Our Services</h1>

              <p className='textcolor'>
                We are a full-service agency with a passion for delivering exceptional solutions. Explore our diverse offerings led by a
                team of talented developers </p>
            </div>
          </div>



          <div className="p-5   lg:space-x-20 animate-left  flex flex-col lg:flex-row items-center pt-1 pb-1  justify-evenly  lg:p-0 xl:p-0 md:p-0 ">

            <div className="  m-4 lg:w-1/2 flex  flex-col   p-4 md:p-8 lg:p-12 xl:p-16">

              <h1 className="text-[20px]  textcolor  md:text-3xl lg:text-4xl xl:text-4xl font-bold mb-4">
                Mobile App Development        </h1>
              <p className=" lg:w-96 textcolor text-[14px]  md:text-[16px] mb-2 lg:mb-4 md:mb-4 xl:mb-4">
                Reach your audience on the go with our custom mobile application solutions. Our team of skilled
                developers ensures that your app is not only user-friendly but also feature-rich, providing a seamless
                experience across various devices and platforms. We leverage the latest technologies to bring your ideas
                to life, offering robust and scalable mobile solutions that meet your business objectives. From concept to deployment,
                we guide you through every step, ensuring your mobile app stands out in the competitive market.
              </p>
            </div>

            <div className="lg:w-1/2">
              <img src={image1} className="h-[200px] w-[300px] md:w-[310px] md:h-[300px] lg:h-[400px] lg:w-[500px] box object-cover rounded-md" alt="Hand" />
            </div>
          </div>







          <div className="p-5 animate-right w-[100vw]    text-black  flex flex-col lg:flex-row items-center   pt-1 pb-1 lg:text-black justify-between  lg:p-0 xl:p-0 md:p-0 ">
            <div className=" md:mr-3  md:ml-16">
              <img src={image2} className="h-[200px] w-[300px] md:w-[310px] md:h-[300px] lg:h-[400px] lg:w-[500px] box object-cover rounded-md" alt="Hand" />
            </div>
            <div className="  m-4 lg:w-1/2 flex  flex-col   p-4 md:p-8 lg:p-12 xl:p-16">

              <h1 className="text-[20px]   md:text-3xl lg:text-4xl xl:text-4xl font-bold mb-4 textcolor">
                Web Development
              </h1>
              <p className=" lg:w-96 text-[14px] w-50 textcolor  md:text-[16px] mb-2 lg:mb-4 md:mb-4 xl:mb-4">
                Elevate your online presence with our cutting-edge web development services. Our team of experienced developers
                specializes in creating responsive and dynamic websites tailored to your unique requirements. We focus on delivering
                high-performance web solutions that engage users and drive business growth. Whether you need an e-commerce platform,
                a corporate website, or a custom web application, we have the expertise to bring your vision to reality. Let us help
                you establish a strong digital presence and leave a lasting impression on your visitors.
              </p>
            </div>


          </div>

          <div className="p-5 animate-left  lg:space-x-20  text-black flex flex-col lg:flex-row items-center md:pt-16 pb-10 md:space-x-10 lg:text-black justify-around  lg:p-10 xl:p-10 md:p-10 ">

            <div className="  m-2 lg:w-1/2 flex  flex-col   p-4 md:p-8 lg:p-12 xl:p-16">

              <h1 className="text-[20px]  textcolor md:text-3xl lg:text-4xl xl:text-4xl font-bold mb-4">
                UI/Design
              </h1>
              <p className=" textcolor lg:w-96 text-[14px]  md:text-[16px] mb-2 lg:mb-4 md:mb-4 xl:mb-4">
                Transform your digital presence with our UI design services. Our expert designers
                recognize the pivotal role of user interface design in elevating your overall user
                experience. Embracing a user-centric approach, we blend creativity with usability to
                construct interfaces that not only captivate with their aesthetics but also guarantee
                intuitive navigation. Every detail matters, ensuring our designs not only captivate
                users but also elevate the success of your digital products for an exceptional user experience.

              </p>
            </div>

            <div className="lg:w-1/2">
              <img src={image3} className="h-[200px] box md:h-20 md:w-20 h-12 w-12 w-[300px] md:w-[310px] md:h-[300px] lg:h-[400px] lg:w-[500px] object-cover rounded-md" alt="Hand" />
            </div>
          </div>







          <div className='m-1  lg:space-x-20 lg:w-4/4 flex   flex-col items-center text-white rounded-md'>
            <h1 className='absolute pt-1 font-bold text-[20px] md:text-3xl '>Additional Services</h1>
            <div className="md:space-x-32 m-4 lg:w-3/4 flex justify-around flex-col md:flex-row">
              <div className="mt-1 space-y-1 justify-center flex flex-col items-center p-6 md:text-center">
                <img src={customer} alt="Customer Support" style={{ backgroundColor: 'white' }} className='h-[40px] mt-2 box w-[40px]' />
                <h1 className='sm:font-bold text-xl textcolor'>Customer Support</h1>
                <p className='text-[14px] textcolor md:text-[15px]'>
                  Exceptional customer support is pivotal in today's competitive landscape.
                  Providing proactive assistance through predictive analytics and personalized customer
                  profiles ensures issues are addressed promptly. Multi-channel support, available 24/7,
                  guarantees a seamless experience for customers transitioning between communication channels.
                </p>
              </div>
              <div className="mt-1 space-y-1 justify-center text-white flex flex-col items-center p-6 md:text-center">
                <img src={software} alt="Custom Software Development" style={{ backgroundColor: 'white' }} className='h-[40px] box mt-2 w-[50px]' />
                <h1 className='sm:font-bold text-xl textcolor'>Custom Software Development</h1>
                <p className='text-[14px] md:text-[15px] textcolor'>
                  he integration of custom software development and customer support creates a powerful synergy.
                  Integrated support tools within custom software establish unified platforms for seamless customer
                  interactions. Designing software with a customer-centric approach, featuring user-friendly
                  interfaces and quick access to support features, enhances the overall user experience.

                </p>
              </div>
            </div>
          </div>


          <div className=' text-[14px]   md:text-3xl flex-col flex  text-white font-bold text-center p-1'>Connect with Elegance! Tap
            and Lets Begin

            <Link to={CONTACT_US_PATH}><button className='bg-transparent mt-4 text-lg font-bold border-2  md:mb-4   p-1 md:p-1 px-1 w-44   border-solid  border-slate-300   rounded-lg text-white'>Contact Now         <FontAwesomeIcon icon={faPhoneVolume} style={{ color: "#f1f5f9", }} />
            </button></Link> </div>

        </div>
      </div>
      <Footer />
    </>
  )
}
