import React from 'react'

function BottonComponent(props) {
  const { buttonText, onClick } = props
  return (
    <div><button onClick={onClick} type='submit' className="bg-transparent my-2 w-full   hover:bg-blue-1000 text-slate-400 font-semibold  py-2 px-4 border border-slate-400 hover:text-white rounded placeholder-slate-400">
      {buttonText}
    </button></div>
  )
}

export default BottonComponent