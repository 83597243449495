import React from 'react'

function InputFeildComponent(props) {
  const { value, field, type, placeholder, textFeild = false, onChange, errorMessage } = props
  return (
    <div className='my-5'>
  {textFeild ? (
    <div>
      <label className="block">
        <textarea
          type={type}
          value={value}
          placeholder={placeholder}
          required
          onChange={onChange}
          className="mt-1 h-28 block w-full px-3 py-2 bg-transparent border border-slate-300 rounded-md text-sm shadow-sm text-slate-400 font-body
          focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 "
        ></textarea>
      </label>
    </div>
  ) : (
    <label className="block">
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        required
        onChange={onChange}
        className="mt-1 block w-full px-3 py-2 bg-transparent border border-slate-300 rounded-md text-sm shadow-sm text-slate-400 font-body focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
      />
    </label>
  )}

  {errorMessage && errorMessage?.length > 0 && errorMessage.map(item => (
    <p key={item.name} className='mt-1 font-body text-red-500 text-xs'>
      {item.name}
    </p>
  ))}
</div>

  )
}

export default InputFeildComponent