import React from 'react'
import Navbar from '../components/UserInterface/Navbar'
import Section from '../components/UserInterface/SectionHome.jsx'
import ThreeImagesRow from '../components/Reusable/ThreeImagesCard.jsx'
import Stacks from '../components/UserInterface/Stacks.jsx'
import Footer from '../components/UserInterface/Footer.jsx'
import PortfolioCard from '../components/UserInterface/PortfolioCard.jsx'
import ContactCard from '../components/UserInterface/ContactCard.jsx'

export default function Home() {
  return (
    <>
      <Section />
      <ThreeImagesRow />
      <Stacks />
      <div>
        <PortfolioCard home={true} />
      </div>
      <ContactCard />
      <Footer />

    </>

  )
}
