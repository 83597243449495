export const HOME_PATH = "/";
export const USER_PATH = "/users";
export const LOGIN_PATH = "/login";
export const CONTACT_PATH = "/contact";
export const ABOUT_PATH = "/about";
export const SERVICES_PATH = "/services";
export const PORTFOLIO_PATH = "/portfolio";
export const CONTACT_US_PATH = "/contact-us";

export const BASE_URL = "http://localhost:8000/api";
