import React from 'react';
import ParticleBackground from './ParticleBackground'; // Import ParticleBackground component
import Card from './Card.jsx';
import { Link } from 'react-router-dom';
import { ABOUT_PATH } from '../../Utilities/Constants';
import Navbar from './Navbar.jsx';

export default function Section() {
  return (
    <>
      {/* Background container with ParticleBackground */}
      <div >
        <ParticleBackground />{/* Render ParticleBackground component */}
        <div className="">
          {/* Content inside the background */}
          <Navbar />
          <div className="flex items-center justify-center  pt-28 min-h-[480px]   h-screen text-white ">
            <div className="text-center bottom-animation max-w-4xl  p-4 md:p-8 lg:p-12 xl:p-16  mx-auto">
              <h1 className="text-[16px]  md:text-3xl lg:text-4xl xl:text-4xl font-bold mb-4">
                Welcome to Softlyfy - Your Digital Transformation Partner
              </h1>
            <p className="text-[14px] md:text-[16px] mb-4">
                At Softlyfy, we embark on a journey of innovation, transforming ideas into digital reality.
              </p>
              <p className="text-[14px] md:text-[16px] mb-4">
                Our passion lies in crafting seamless solutions that empower businesses and
              </p>
              <p className="text-[14px] md:text-[16px] mb-4">
                individuals to thrive in the digital era.
              </p>
              <Link to={ABOUT_PATH}>
                <button className="bg-blue-500 hover:bg-blue-700 cursor-pointer text-white py-2 px-4 md:py-2 md:px-6 lg:py-2 lg:px-8 xl:py-3 xl:px-10 rounded-md transition duration-300 ease-in-out">
                  Read more
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="pt-10  bg-black">
          <Card />
        </div> */}
      </div>

    </>
  );
}
