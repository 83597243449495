import logo from './logo.svg';
import './App.css';
import AppRouter from './src/Router/Router';

function App() {
  
  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
