import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faXmark } from '@fortawesome/free-solid-svg-icons';

function DocumentPicker(props) {
    const { onChange, file, onCancelClick, errorMessage } = props;

    return (
        <div className="bg-transparent border-solid border border-slate-300 flex flex-col justify-center items-center px-2 rounded-lg relative">
            <input
                type="file"
                id="documentPicker"
                accept=".doc, .docx, .pdf, .xls, .xlsx, .txt, .jpg, .png"
                className="hidden"
                onChange={onChange}
            />
            {file ? (
                <div id="selectedDocument" className="my-2 flex items-center justify-center  bg-transparent p-1 rounded-lg">
                    <FontAwesomeIcon className="cursor-pointer text-red-500 w-5 h-5 mr-2" icon={faXmark}  onClick={onCancelClick} />
                    <span className="font-body text-slate-400 ">{file?.name}</span>
                </div>
            ) : (
                <div className="my-2 flex items-center">
                    <span className="text-slate-400 font-body">No File Choosen</span>
                </div>
            )}
            <label htmlFor="documentPicker" className="cursor-pointer bg-transparent border font-body text-slate-400 px-4 py-1 my-4 rounded-md hover:text-white transition duration-300">
                Browse
            </label>
            {

                errorMessage && errorMessage?.length > 0 && errorMessage.map(item => < p className='mt-1 font-body text-red-500 text-xs' >{item.name}</p>)
            }

        </div>
    );
}

export default DocumentPicker;
