import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import image1 from './Assets/Services1.jpg';
import image1 from '../../Assets/services1.0.jpg';
import image2 from '../../Assets/services2.0.jpg';
import image3 from '../../Assets/web3.0.jpg';
import { motion, useAnimate, useAnimation, useInView } from 'framer-motion'
import { Link } from 'react-router-dom';
import { SERVICES_PATH } from '../../Utilities/Constants';

const data = [
  {
    text: 'Create visually appealing interfaces that balance aesthetics.',
    name: '✨ UI/UX Design',
    path: image2,
    id: 1,
    navigation: SERVICES_PATH
  },
  {
    text: 'Elevate your online presence with cutting-edge websites.',
    name: '✨ Web Development',
    path: image3,
    id: 2,
    navigation: SERVICES_PATH
  },
  {
    text: 'Reach your audience on the  go with custom mobile applications.',
    name: '📱 Mobile App Solutions',
    path: image1,
    id: 3,
    navigation: SERVICES_PATH
  },
];

const ThreeImagesRow = () => {


  const imageStyles =
    'w-auto h-auto md:w-52 md:h-52 lg:w-80 md:h-56  bg-transparent rounded-md  bg-white   ';

  const animateRef = useRef(null)
  const isInView = useInView(animateRef, { once: true })
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <>
      <div className='relative bg-black '>
        <div style={{ background: 'linear-gradient(to right, black 0%, #001529 30%, #094D80 50%, #001529 70%, black 100%)', opacity: '0.5' }}></div>
        <div className='flex flex-col justify-center items-center space-y-1 pt-24 !text-white'>
          <p className='text-lg md:text-4xl font-bold' >Our Services</p>
          <p className='text-[14px] md:text-[16px]'>Get cutting-edge technology solutions for your evolving needs</p>
        </div>
        <motion.div
          ref={animateRef}
          variants={{ hidden: { opacity: 0, y: 75 }, visible: { opacity: 1, y: 0 } }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          <div className='p-5 md:p-10 hover:cursor-pointer'>
            <div className="w-full flex justify-around items-center flex-col md:flex-row">

              {data.map((val) => (
                <div key={val.id} className='flex m-2  hover:text-white  bg-white rounded-lg   hover:h-96 w-80 box flex-col text-center items-center relative overflow-hidden border border-gray-200'>
                  <img src={val.path} alt="image1" className={`${imageStyles} rounded-t-lg w-full  h-40 object-cover`} />
                  <div className='p-4  bg-gray-50'>
                    <p className="text-lg font-bold   text-gray-800">{val.name}</p>
                    <p className="text-sm  text-gray-600 mt-2">{val.text}</p>
                    <div className="mt-4">
                      <Link to={val.navigation} className="text-blue-500 hover:underline transition duration-300">Learn More</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </>

  );
};
export default ThreeImagesRow;
