import React from 'react';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sustain from '../../Assets/sustain.png'
import integrity from '../../Assets/integrity.png'
import innovations from '../../Assets/innovations.png'
import about1 from '../../Assets/about1.png'
import about2 from '../../Assets/about2.png'
import about3 from '../../Assets/about3.png'


export default function AboutSecondHead() {
  return (
    <>
      {/* <div className='flex textcolor flex-col justify-center md:h-[33vh] p-6'>

        <div className='text-4xl  font-bold text-center mb-8'>Our Values</div>

        <div className='flex flex-col sm:flex-row justify-center items-center mb-8'> */}

          {/* Column 1 */}
          {/* <div className='flex flex-row items-center mb-6 md:mb-0'>
          <img src={integrity} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' />
            <div className='text-normal'>
              <h2 className='font-semibold mb-0  md:text-[20px] text-[12px] '>Integrity</h2>
              <p className='text-[12px] md:text-[13px] '>We uphold the highest standards of honesty <br/> and  transparency in all our dealings.</p>
            </div>
          </div> */}

          {/* Column 2 */}
          {/* <div className='flex flex-row items-center mb-6 mr-10 md:mr-0  md:mb-0'>
          <img src={innovations} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' />
            <div className='text-normal'>
              <h2 className='font-semibold mb-0 md:text-[20px] text-[12px] '>Innovation</h2>
              <p className='text-[12px] md:text-[13px] '>Constantly pushing the boundaries to  <br/>bring  innovative solutions to our clients.</p>
            </div>
          </div>
         

          {/* Column 3 */}
          {/* <div className='flex flex-row items-center mb-0 mr-10 md:mr-0  md:mb-0'>
          <img src={sustain} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' />
            <div className='text-normal'>
              <h2 className='font-semibold  mb-0 md:text-[20px] text-[12px] '>Sustainability</h2>
              <p className='text-[12px] md:text-[13px] '> Our commitment minimizing our  <br/> 
              environmental impact and contributing to greener planet.</p>
            </div>
          </div>

        </div>

      </div>

      <div className='flex textcolor flex-col justify-center h-[35vh] p-6'>  */}

{/* <div className='text-4xl  font-bold text-center mb-8'>Our Values</div> */}

{/* <div className='flex flex-col sm:flex-row justify-center items-center mb-8'> */}

  {/* Column 1 */}
  {/* <div className='flex flex-row items-center mb-6 md:mb-0'>
  <img src={integrity} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' />
    <div className='text-normal'>
      <h2 className='font-semibold mb-0  md:text-[20px] text-[12px] '>Integrity</h2>
      <p className='text-[12px] md:text-[13px] '>We uphold the highest standards of honesty <br/> and  transparency in all our dealings.</p>
    </div>
  </div> */}

  {/* Column 2 */}
  {/* <div className='flex flex-row items-center mb-6 mr-10 md:mr-0  md:mb-0'>
  <img src={innovations} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' />
    <div className='text-normal'>
      <h2 className='font-semibold mb-0 md:text-[20px] text-[12px] '>Innovation</h2>
      <p className='text-[12px] md:text-[13px] '>Constantly pushing the boundaries to  <br/>bring  innovative solutions to our clients.</p>
    </div>
  </div> */}

  {/* Column 3 */}
  {/* <div className='flex flex-row items-center sm:mr-2 md:mr-0'>
  <img src={sustain} className='text-3xl mb-0 mr-0 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' />
    <div className='text-normal'>
      <h2 className='font-semibold  mb-0 md:text-[20px] text-[12px] '>Sustainability</h2>
      <p className='text-[12px] md:text-[13px]'> Our commitment minimizing our  <br/> 
      environmental impact and contributing to a greener planet.</p>
    </div>
  </div>

</div>

</div> */}

{/* 
      <div className='flex textcolor flex-col justify-center md:mr-0 h-[50vh] md:h-[30vh] p-8'>


        <div className='flex flex-col sm:flex-row justify-center items-center mb-8'>

          {/* Column 1 */}
          {/* <div className='flex flex-row items-center mb-6 md:mb-0'>
          <img src={about3} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-9 w-9 rounded-lg rotating-image' />
            <div className='text-normal'>
              <h2 className='font-semibold mb-0  md:text-[20px] text-[12px] '>Ethical Conduct:</h2>
              <p className='text-[12px] md:text-[13px] '>Demonstrating integrity by consistently
              <br/> adhering to ethical principles and   moral values  </p>
            </div>
          </div> */}

          {/* Column 2 */}
          {/* <div className='flex flex-row items-center mb-6 md:mb-0'>
          <img src={about2} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-9 w-9 rounded-lg rotating-image' />
            <div className='text-normal'>
              <h2 className='font-semibold mb-0 md:text-[20px] text-[12px] '>Accountability</h2>
              <p className='text-[12px] md:text-[13px] '>Taking responsibility for our actions, decisions,  <br/>
              and their consequences      ensuring transparency <br/>and honesty in our interactions with stakeholders.</p>
            </div>
          </div> */}

          {/* Column 3 */}
          {/* <div className='flex flex-row items-center'>
          <img src={about1} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-9 w-9 rounded-lg rotating-image' />
            <div className='text-normal'>
              <h2 className='font-semibold  mb-0 md:text-[20px] text-[12px] '>Trustworthiness</h2>
              <p className='text-[12px] md:text-[13px] '> Building trust through reliability, consistency,  <br/> 
              and dependability in our    words and actions .</p>
            </div>
          </div>

        </div> */}

      {/* </div> */}


      <div className='flex textcolor flex-col justify-center  sm:min-h-[66vh] p-0'>

      <div className='text-4xl font-bold text-center mb-8'>Our values</div>
      
      <div className='flex flex-col sm:flex-row justify-center items-center mb-8'>
        {/* Column 1 */}
        <div className='flex flex-row items-center mb-4 sm:mb-0'>
          <img src={about1} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Core Values' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Trustworthiness</h2>
            <p className='text-[12px] md:text-[13px]'>Building trust through reliability, consistency, and dependability words and actions </p>
          </div>
        </div>

        {/* Column 2 */}
        <div className='flex flex-row items-center mb-4 sm:mb-0'>
          <img src={about2} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Mission Statement' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Accountability</h2>
            <p className='text-[12px] md:text-[13px]'>Taking responsibility for our actions, decisions,<br/>and their consequences ensuring transparency and honesty in our interactions with stakeholders </p>
          </div>
        </div>

        {/* Column 3 */}
        <div className='flex flex-row items-center'>
          <img src={about3} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Vision for Tomorrow' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Ethical Conduct</h2>
            <p className='text-[12px] md:text-[13px]'>Demonstrating integrity by consistently adhering to ethical principles and   moral values</p>
          </div>
        </div>
      </div>
      
      
      <div className='flex flex-col sm:flex-row justify-center mt-10 items-center mb-8'>
        {/* Column 1 */}
        <div className='flex flex-row items-center mb-4 sm:mb-0'>
          <img src={integrity} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Core Values' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Integrity</h2>
            <p className='text-[12px] md:text-[13px] '>We uphold the highest standards of honesty  and  transparency in all our dealings</p>
          </div>
        </div>

        {/* Column 2 */}
        <div className='flex flex-row items-center mb-4 sm:mb-0'>
          <img src={innovations} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Mission Statement' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Innovations</h2>
            <p className='text-[12px] md:text-[13px]'>Constantly pushing the boundaries to  bring  innovative solutions to our clients.</p>
          </div>
        </div>

        {/* Column 3 */}
        <div className='flex flex-row items-center'>
          <img src={sustain} className='text-3xl mb-0 mr-2 md:h-24 md:w-24 h-16 w-16 rounded-lg rotating-image' alt='Vision for Tomorrow' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Sustainibility</h2>
            <p className='text-[12px] md:text-[13px]'>Our commitment minimizing our  
      environmental impact and contributing to a greener planet.</p>
          </div>
        </div>
      </div>
      
    </div>
    </>
  );
}
