import React from 'react';
import laravel from '../../Assets/laravel1.0.png';
import nodejs from '../../Assets/node1.png';
import reactnative from '../../Assets/react-native-logo1.0.png';
import sql from '../../Assets/MySQL-Logo (1).png';
import javascript from '../../Assets/JavaScript-Logo.png';
import css from '../../Assets/css1.0.png';
import Go from '../../Assets/Go-Logo_Blue1.0.png';
import materialui from '../../Assets/materialui1.png';
import mongodb from '../../Assets/mongodb1.png';
import nextjs from '../../Assets/nextjs2.jfif';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Stacks() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    horizontal: false,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1104,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const data = [
    {
      path: laravel,
      name: 'Laravel'
    },
    {
      path: Go,
      name: 'GoLang'
    },
    {
      path: css,
      name: 'CSS'
    },
    {
      path: nodejs,
      name: 'NodeJs'
    },
    {
      path: reactnative,
      name: 'React Native'
    },
    {
      path: javascript,
      name: 'JavaScript'
    },
    {
      path: sql,
      name: 'MySQL'
    },
    {
      path: materialui,
      name: 'Material UI'
    },
    {
      path: mongodb,
      name: 'MongoDB'
    },
    {
      path: nextjs,
      name: 'Next Js'
    },
  ]
  const imageStyles = 'md:w-30 md:h-30 w-20 h-20 bg-transparent p-3 mx-auto ';

  return (
    <>
      <div className='relative  bg-black w-screen overflow-hidden'>
        <p className='text-lg md:text-4xl font-bold textcolor text-center' >Stacks in which we work</p>
        <div className="pb-5 md:pb-10">
          <Slider {...settings}>
            {data.map((val, index) => (
              <div key={index} className='flex mx-0 px-0 justify-center text-center '>
                <div className='p-10'>
                  <img src={val.path} alt={val.name} className={imageStyles} />

                </div>
                <div className='flex flex-col  text-white   mt-4 md:mt-0'>
                  <p className="text-sm md:text-base lg:text-lg">{val.name}</p>
                  {/* <p className="text-sm md:text-base  lg:text-lg pt-0 p-10">{val.text}  </p> */}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>

  );
}




{/* <div className='flex flex-col bg-gradient-to-r from-black via-black to-blue-900 text-white space-y-6'>
      <p className='text-[14px] text-center md:text-[30px] font-bold'>
        Our Stacks In Which We Work
      </p>
      <Slider {...settings}>
      <div className='flex flex-col items-center space-y-2 md:flex-row justify-around space-x-6 hover:bg-gradient-to-r from from-black via-blue-600 to-black'>
        <span></span>
        <div className='text-center p-4 rounded-sm stack-item'>
          <img src={laravel} className='h-20 w-20 md:w-40 md:h-40 ' alt='Laravel' />
          <p className='text-[15px]'>Laravel</p>
        </div>
        <div className='text-center p-4 rounded-sm stack-item'>
          <img src={nodejs} className='md:w-40 md:h-40  h-20 w-20 ' alt='Node.js' />
          <p className='text-[15px]'>Node.js</p>
        </div>
        <div className='text-center p-4 rounded-sm stack-item'>
          <img src={sql} className='md:w-40 md:h-40  h-20 w-20' alt='React Native' />
          <p>React Native</p>
        </div>
        <div className='text-center p-4 rounded-sm stack-item'>
          <img src={javascript} className='md:w-40 md:h-40  h-20 w-20 ' alt='JavaScript' />
          <p className='text-[15px]'>JavaScript</p>
        </div>
        <div className='text-center p-4 rounded-sm stack-item'>
          <img src={reactnative} className='md:w-40 md:h-40  h-20 w-20' alt='MySQL' />
          <p>MySQL</p>
        </div>
      </div>
      </Slider>
    </div> */}