import React from 'react';
import AboutSecondHead from './AboutSecondHead';

export default function AoutFirstHead() {
  return (
    <>
      <div className='flex justify-center textcolor pt-10 h-screen mx-auto flex-col items-center'>
        <div>
          <h1 className='text-4xl font-bold mb-6 '>About Us</h1>
        </div>
        <div className='flex-col md:flex-row flex justify-around md:space-x-5 md:space-y-0 space-y-5 p-4 md:p-14 lg:space-x-36'>
          <div className='animated-text-container w-full md:w-96 left-animation '>
            <h1 className='md:text-4xl text-[14px] font-semibold mb-4'>Our Beliefs</h1>
            <p className='md:text-[13px] lg:text-[15px] text-[12px]'>
              Our story is a testament to passion, dedication, and a shared vision.
              It all began with a group of individuals who shared a common dream –
              to create a digital agency that stands out for its innovation.
              It all began with a group of individuals who shared a common dream –
              to create a digital agency that stands out for its innovation.
            </p>
          </div>
          <div className='animated-text-container w-full md:w-96 right-animation '>
            <h1 className='md:text-4xl text-[14px] font-semibold mb-4'>Our Vision</h1>
            <p className='md:text-[13px] lg:text-[15px] text-[12px]'>
              We envision a future where creativity and technology converge seamlessly.
              Our mission is to inspire and empower businesses through innovative digital solutions.
              We envision a future where creativity and technology converge seamlessly.
              Our mission is to inspire and empower businesses through innovative digital solutions.
            </p>
          </div>
        </div>
        {/* <div className='text-4xl font-bold'>
          Our Values
        </div> */}
      </div>
      {/* <AboutSecondHead /> */}
    </>
  );
}
