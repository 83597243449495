import React from 'react';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import core from '../../Assets/core.png';
import integrity from '../../Assets/integrity.png';
import visionfor from '../../Assets/visionfor.png';
import Footer from './Footer';

export default function AboutThirdHead() {
  return (
    <div className='flex textcolor flex-col justify-center  sm:min-h-[66vh] p-0'>

      <div className='text-4xl font-bold text-center mb-8'>Our Beliefs</div>
      
      <div className='flex flex-col sm:flex-row justify-center items-center mb-8'>
        {/* Column 1 */}
        <div className='flex flex-row items-center mb-4 sm:mb-0'>
          <img src={core} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Core Values' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Core Values</h2>
            <p className='text-[12px] md:text-[13px]'>Empowering growth, fostering innovation, and cultivating integrity for lasting positive impact.</p>
          </div>
        </div>

        {/* Column 2 */}
        <div className='flex flex-row items-center mb-4 sm:mb-0'>
          <img src={integrity} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Mission Statement' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Mission Statement</h2>
            <p className='text-[12px] md:text-[13px]'>Dedicated to excellence, we strive to exceed expectations, making a difference</p>
          </div>
        </div>

        {/* Column 3 */}
        <div className='flex flex-row items-center'>
          <img src={visionfor} className='text-3xl mb-0 mr-2 md:h-20 md:w-20 h-12 w-12 rounded-lg rotating-image' alt='Vision for Tomorrow' />
          <div className='text-normal'>
            <h2 className='font-semibold mb-0 md:text-[20px] text-[12px]'>Vision for Tomorrow:</h2>
            <p className='text-[12px] md:text-[13px]'>Building a world where shared values drive progress and inspire change.</p>
          </div>
        </div>
      </div>
      
    </div>
  );
}
