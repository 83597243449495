import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import computer from '../../Assets/computer.png';
const PortfolioCard = ({ home }) => {


  return (
    <div   className='relative  bg-black'>
      <div style={{ background: 'linear-gradient(to right, black 0%, #001529 30%, #094D80 50%, #001529 70%, black 100%)', opacity: '0.5' }}></div>
      <div className='  font-bold  textcolor flex flex-col items-center p-6 md:p-10 text-sm md:text-4xl'>
        {home && <h1 className='textcolor font-bold font-body text-2xl md:text-4xl mb-4'>Our Portfolio</h1>}

        {/* Card (for larger screens) */}
        <div className="w-full hidden sm:hidden lg:flex md:flex lg:w-4/6 md:w-full mt-5 border-2 border-textcolor  relative overflow-hidden rounded-lg  flex-row  justify-center  items-center">
          {/* Image */}
          <img
            src={computer}
            alt="Portfolio Image 1"
            className="w-full h-auto md:w-1/2  mb-4 shadow-bottom"
          />
          {/* Content */}
          <div className="textcolor p-4 flex flex-col w-full md:w-1/2 lg:w-2/3 items-center">
            {/* Website Detail */}
            <div>
              <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">Advance Proff Portal</h2>
              <p className='textcolor font-body text-xs  md:text-sm lg:text-sm text-center'>
                The website serves as a platform connecting clients and customers through Admin and User roles. Admins have control over plans, users, and order workflows, generating PDF invoices dynamically. They can approve/disapprove orders, sending order details to clients. The system includes a forgot password feature for enhanced security. Users can easily place orders for categorized plans, and the system ensures secure authentication and scalability. Ongoing refinements, guided by user feedback and thorough testing, contribute to adaptability and continuous improvement.
              </p>
            </div>
            {/* Tech Stacks */}
            <div className="h-1/2 textcolor p-4 overflow-y-auto w-full">
              <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">Tech Stacks</h2>
              <div className='flex justify-center'>
                <FontAwesomeIcon icon="fa-brands fa-php" className='mx-2 md:mx-4 mb-2' style={{ color: '#787cb4' }} />
                <FontAwesomeIcon icon="fa-solid fa-database" className='mx-2 md:mx-4 mb-2' style={{ color: '#00618a' }} />
                <FontAwesomeIcon icon="fa-brands fa-js" className='mx-2 md:mx-4 mb-2' style={{ color: 'yellow' }} />
                <FontAwesomeIcon icon="fa-brands fa-bootstrap" className='mx-2 md:mx-4 mb-2' style={{ color: '#890afc' }} />
              </div>
            </div>
          </div>
        </div>

        {/* Card (for small screens) */}
        <div className="lg:hidden md:hidden sm:block w-full mt-5 border-4 rounded-lg border-blue-500 bg-whites relative overflow-hidden  flex-col items-center">
          <div className="flex flex-row items-center">
            <img
              src={computer}
              alt="Portfolio Image 1"
              className="w-2/3 h-auto mb-4 shadow-bottom"
            />
            <h2 className="text-xl font-bold mr-4">Advance Proff Portal</h2>
          </div>
          <div className="text-white flex flex-col w-full px-4">
            {/* Website Detail */}
            <div>
              <p className='text-white font-body text-xs  text-center'>
                The website serves as a platform connecting clients and customers through Admin and User roles. Admins have control over plans, users, and order workflows, generating PDF invoices dynamically. They can approve/disapprove orders, sending order details to clients. The system includes a forgot password feature for enhanced security. Users can easily place orders for categorized plans, and the system ensures secure authentication and scalability. Ongoing refinements, guided by user feedback and thorough testing, contribute to adaptability and continuous improvement.
              </p>
            </div>
            {/* Tech Stacks */}
            <div className=" text-white p-4">
              <h2 className="text-xl font-bold mb-4 text-center">Tech Stacks</h2>
              <div className='flex justify-center'>
                <FontAwesomeIcon icon="fa-brands fa-php" className='mx-4 mb-2' size='3x' style={{ color: '#787cb4' }} />
                <FontAwesomeIcon icon="fa-solid fa-database" className='mx-4 mb-2' size='3x' style={{ color: '#00618a' }} />
                <FontAwesomeIcon icon="fa-brands fa-js" className='mx-4 mb-2' size='3x' style={{ color: 'yellow' }} />
                <FontAwesomeIcon icon="fa-brands fa-bootstrap" className='mx-4 mb-2' size='3x' style={{ color: '#890afc' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default PortfolioCard;
