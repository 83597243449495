import React, { useEffect } from 'react';
import AboutFirstHead from '.././components/UserInterface/AoutFirstHead'
import Footer from '..//components/UserInterface/Footer';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AboutSecondHead from '../components/UserInterface/AboutSecondHead';
import AboutThirdHead from '../components/UserInterface/AboutThirdHead';
import Navbar from '../components/UserInterface/Navbar';
function About() {

  useEffect(()=>{
document.title="Softlyfy - About"
  },[])
  return (
    <div className='bg-black'>
      <Navbar/>
      <section className='sections text-white ' >
        <AboutFirstHead />
      </section >
      <section className='sections ' >
        <AboutSecondHead />
      </section >
      <section className='sections  ' >
        <AboutThirdHead  />
        <div className='mt-3'>
          <Footer />
        </div>

      </section >
    </div>
  );
}

export default About;
