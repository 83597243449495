import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function LoaderComponent() {
  return (
    <div className="flex justify-center items-center my-2">
      <div className="spinner">
        <FontAwesomeIcon className="fas fa-spinner fa-spin text-white text-4xl" icon={faSpinner}  />
      </div>
    </div>
  )
}

export default LoaderComponent