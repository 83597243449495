

import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ABOUT_PATH, CONTACT_US_PATH, HOME_PATH, PORTFOLIO_PATH, SERVICES_PATH } from '../../Utilities/Constants';
import { Link, useLocation } from 'react-router-dom';
import myFile from '../../Assets/logo.png';
import ButtonContact from '../Reusable/ButtonContact';

const navigation = [
  { name: 'Home', href: HOME_PATH },
  { name: 'About', href: ABOUT_PATH },
  { name: 'Services', href: SERVICES_PATH },
  // { name: 'Portfolio', href: PORTFOLIO_PATH },
  { name: 'Contact', href: CONTACT_US_PATH },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}




export default function Navbar({ className }) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [navbar, setnavbar] = useState(false)
  useEffect(() => {
    if (location.pathname == ABOUT_PATH) {
      setnavbar(true)
    }
    else {
      setnavbar(false)
    }
  })

  return (
    <nav className={`bg-white ${navbar ? 'fixed' : ''}  w-full p-6 lg:p-2 md:p-4 sm:p-2 flex absolute justify-between ${className}`}>
      <div className="mt-1">
        <img src={myFile} alt="Logo" className="md:h-10 md:w-50 h-10 w-30 ml-2" />
      </div>

      <div className="mx-auto max-w-7xl  px-4 sm:px-6 lg:px-8 flex items-center justify-between w-full">
        <div className="flex-grow">
          <div className="hidden sm:flex   items-center justify-center space-x-12 text-md lg:text-xl sm:text-sm md:text-xl font-semibold">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  'text-black hover:bg-black hover:text-white px-2 py-1 rounded-md font-body text-base',
                  item.name === 'Contact' && 'xl:hidden lg:hidden sm:inline'
                )}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="ml-auto">
          <Link to={CONTACT_US_PATH}>
            <ButtonContact
              text="Contact us"
              color="gradient-purple-black"
              onClick={() => console.log('contact button')}
            />
          </Link>
        </div>

        <div className="sm:hidden">
          <button
            onClick={() => setOpen(!open)}
            className="text-gray-500 hover:text-black focus:outline-none focus:ring focus:border-gray-300"
          >
            {open ? (
              <FontAwesomeIcon icon="fa-solid fa-xmark" />
            ) : (
              <FontAwesomeIcon icon="fa-solid fa-bars" className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {open && (
        <div className="sm:hidden absolute top-16 right-0 bg-[#f5f5f5] p-2 space-y-1 px-2 pb-3 pt-2 w-full">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={() => setOpen(false)}
              className={classNames(
                'text-black hover:text-black block rounded-md px-3 py-2 text-base font-medium',
                item.current ? 'bg-gray-900 text-white' : 'text-gray-700'
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </nav>

  );
}
