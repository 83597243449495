import React, { useState } from 'react'
import InputFeildComponent from '../Reusable/InputFeildComponent'
import DocumentPicker from '../Reusable/DocumentPicker'
import BottonComponent from '../Reusable/BottonComponent'
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitContact } from '../../Services/ContactService'
import LoaderComponent from '../Reusable/LoaderComponent';

function ContactCard(props) {
    const { name, ...restProps } = props
    const [firstName, setFirstName] = useState("");
    const [lastNAme, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [file, setFile] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [firstNameError, setfirstNameError] = useState(null);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [messagError, setMessagError] = useState(null);
    const [hearingError, setHearingError] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [captchaError, setCaptchaError] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFileError(null);
        setFile(selectedFile);

    };
    const handleCaptchaSubmit = (val) => {
        setIsCaptchaVerified(!!val);
        if (val) {
            setCaptchaError(null);
        }
    }
    const handleSubmit = () => {
        if (!isCaptchaVerified) {
            setCaptchaError(["Captcha Required"]);
            return
        }
        setLoading(true);
        let submitObj = {
            first_name: firstName,
            last_name: lastNAme,
            email: email,
            message: message,
            phone_number: phoneNumber,
            file,
            hearing: "Other"

        }
        SubmitContact(submitObj).then(suc => {

            setErrorMessage({ class: "green", message: suc?.message })
        }, err => {
            setErrorMessage({ class: "red", message: err?.message })
            if (err.status == 401) {

                if (err?.data?.hasOwnProperty("first_name")) {
                    let errors = [];
                    err.data.first_name.map(item => errors.push({ name: item }));
                    setfirstNameError(errors);
                }
                if (err?.data?.hasOwnProperty("email")) {
                    let errors = [];
                    err.data.email.map(item => errors.push({ name: item }));
                    setEmailError(errors);
                }
                if (err?.data?.hasOwnProperty("message")) {
                    let errors = [];
                    err.data.message.map(item => errors.push({ name: item }));
                    setMessagError(errors);
                }
                if (err?.data?.hasOwnProperty("phone_number")) {
                    let errors = [];
                    err.data.phone_number.map(item => errors.push({ name: item }));
                    setPhoneError(errors);
                }
                if (err?.data?.hasOwnProperty("hearing")) {
                    let errors = [];
                    err.data.hearing.map(item => errors.push({ name: item }));
                    setHearingError(errors);
                }
                if (err?.data?.hasOwnProperty("file")) {
                    let errors = [];
                    err.data.file.map(item => errors.push({ name: item }));
                    setFileError(errors);
                }
            }
        })
    }
    return (
        <>
            <div className='relative bg-black'>
                <div className=' flex flex-col items-center justify-center py-24 text-center'>
                    <h1 className='text-white font-bold  font-body text-4xl'>Contact Us</h1>
                    <p className='text-white p-5 font-body'>Connect with us effortlessly using the form below. We're here to assist you promptly and look forward to hearing from you soon.</p>
                    <section className='flex flex-col lg:flex-row justify-between items-center'>
                        <div className='mx-2 lg:mx-16'>
                            <h2 className='text-white font-bold font-body text-4xl text-center'>Let's connect!</h2>
                            <p className='text-white mt-4 font-body text-center'>
                                Connect with us effortlessly using the form below. We're here to assist you promptly and look forward to hearing from you soon.
                            </p>
                        </div>
                        <div className='my-8 mx-3  lg:my-20 p-6 lg:p-12 flex flex-col justify-center rounded-lg border border-solid s md:w-11/12 lg:w-2/3 !bg-gradient-to-b !from-gray-800 !via-gray-900 !to-080808  !shadow-lg' >

                            {
                                errorMessage && <div className={`w-full bg-${errorMessage?.class}-500 rounded-md font-body px-2 py-2`}>
                                    <p className='text-white font-body'>Message: {errorMessage?.message}</p>
                                </div>
                            }
                            <div>
                                <InputFeildComponent placeholder="First Name" value={firstName} errorMessage={firstNameError} onChange={(e) => {
                                    setfirstNameError(null);
                                    setFirstName(e.target.value)
                                }} />
                            </div>
                            <div>
                                <InputFeildComponent placeholder="Last Name" value={lastNAme} onChange={(e) => { setLastName(e.target.value) }} />
                            </div>
                            <div>
                                <InputFeildComponent placeholder="Email" value={email} errorMessage={emailError} onChange={(e) => {
                                    setEmailError(null);
                                    setEmail(e.target.value)
                                }} />
                            </div>
                            <div>
                                <InputFeildComponent placeholder="Phone Number" value={phoneNumber} errorMessage={phoneError} onChange={(e) => {
                                    setPhoneError(null);
                                    setPhoneNumber(e.target.value)
                                }} />
                            </div>
                            <div>
                                <InputFeildComponent placeholder="Message" value={message} errorMessage={messagError} textFeild={true} onChange={(e) => {
                                    setMessagError(null);
                                    setMessage(e.target.value)
                                }} />
                            </div>
                            <div>
                                <DocumentPicker onChange={handleFileChange} file={file} onCancelClick={() => {
                                    setFile(null)
                                }} errorMessage={fileError} />
                            </div>
                            <div className='my-2'>
                                <ReCAPTCHA
                                    sitekey="6Lc7NFQpAAAAAG18ozQCmbRDxGqbYfSbu6-LFO5r"
                                    onChange={handleCaptchaSubmit}
                                />
                                {
                                    captchaError && captchaError?.length > 0 && captchaError.map(item => < p className='mt-1 font-body text-red-500 text-xs' >{item}</p>)
                                }
                            </div>
                            <div>
                                {
                                    loading ? <LoaderComponent /> : <BottonComponent buttonText="Submit" onClick={handleSubmit} />
                                }

                            </div>
                        </div>
                    </section>
                </div>
                <div>

                </div>
            </div>
        </>
    )
}

export default ContactCard