import React from 'react';
import Home from '../../Screens/Home';
import { Link } from 'react-router-dom';
import { ABOUT_PATH, CONTACT_PATH, CONTACT_US_PATH, HOME_PATH, LOGIN_PATH, PORTFOLIO_PATH, SERVICES_PATH, USER_PATH } from '../../Utilities/Constants'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Footer() {
    const FACEBOOK_LINK = "https://www.facebook.com/softlyfy/";
    const INSTA_LINK = "https://www.instagram.com/softlyfy7/";
    const TWITTER_LINK = "https://twitter.com/softlyfy7";
    const LINKEDIN_LINK = "https://www.linkedin.com/company/softlyfy/?viewAsMember=true";
    return (
        <>
            <footer className=" relative bg-black text-white">
                <div className='container mx-auto py-4 px-4 md:px-8 lg:px-16'>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8">
                        <div className="mb-8 md:mb-0">
                            <h5
                                className="mb-6 font-bold uppercase text-[14px] md:text-3xl   font-body dark:text-neutral-200">
                                Services
                            </h5>
                            <ul className="mb-2 ">
                                <li >
                                    <a className="md:text-[18px] text-[14px] footers-text  font-body dark:text-neutral-200"
                                    >Web Development </a>
                                </li>
                                <li >
                                    <a className=" footers-text md:text-[18px] text-[14px]   font-body  dark:text-neutral-200"
                                    >App Development</a>
                                </li>
                                <li >
                                    <a className=" footers-text md:text-[18px]  font-body text-[14px]  dark:text-neutral-200"
                                    >UI/UX Designing</a>
                                </li>
                                <li >
                                    <a className=" footers-text md:text-[18px] text-[14px]  font-body  dark:text-neutral-200"
                                    >Software Development</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h5
                                className="mb-6 text-[14px] md:text-3xl  font-bold uppercase  font-body dark:text-neutral-200">
                                Company
                            </h5>
                            <ul className="mb-2 ">
                                <li className='gap'>
                                    <Link to={HOME_PATH} className="  font-body   footers-text md:text-[18px]  text-[14px] dark:text-neutral-200"
                                    >Home</Link>
                                </li>
                                <li className='gap'>
                                    <Link to={SERVICES_PATH} className=" font-body  text-white md:text-[18px]  footers-text  text-[14px] dark:text-neutral-200"
                                    >Services</Link>
                                </li>
                                {/* <li className='gap'>
                                        <Link to={PORTFOLIO_PATH} className=" font-body text-black md:text-[18px]  footers-text  text-[14px] dark:text-neutral-200"
                                        >Portfolio</Link>
                                    </li> */}
                                <li className='gap'>
                                    <Link to={ABOUT_PATH} className=" font-body  md:text-[18px]  footers-text  text-[14px] dark:text-neutral-200"
                                    >About</Link>
                                </li>
                                <li className='gap'>
                                    <Link to={CONTACT_US_PATH} className=" font-body  md:text-[18px]  footers-text  text-[14px] dark:text-neutral-200"
                                    >Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <hr className='border  border-white xl:mx-24 lg:mx-20 md:mx-8 sm:mx-2  mx-2' />
                {/* <!--Copyright section--> */}
                <div className='flex flex-col    md:flex-row lg:justify-between items-center lg:mx-20 md:mx-12 py-4 flex-wrap md:justify-center'>
                    <div className=" mb-4 md:mb-0  md:text-center">
                        <p className='text-center dark:bg-neutral-700 dark:text-neutral-200 font-body'>
                            {/* Contact us: <a href="tel:+123456789" className="text-white dark:text-neutral-200">+123 456 789</a> | */}
                            Email: <a href="mailto:softlyfy7@gmail.com" className="text-white dark:text-neutral-200">softlyfy7@gmail.com</a>
                        </p>
                    </div>
                    <div className="ml-4 mb-4 md:mb-0 md:text-center">
                        <p className='text-center  dark:bg-neutral-700 dark:text-neutral-200 font-body'>
                            Copyright © {moment().format("YYYY")}  Softlyfy.com. All Rights Reserved.
                        </p>
                    </div>
                    <div className="">
                        <div className="flex justify-center items-center">
                            <a className="mr-4 text-white dark:text-neutral-200" target='_blank' href={FACEBOOK_LINK}>
                                <FontAwesomeIcon icon={['fab', 'facebook']} className=' w-6 h-6' />
                            </a>
                            <a className="mr-4 text-white dark:text-neutral-200" target='_blank' href={LINKEDIN_LINK}>
                                <FontAwesomeIcon icon={['fab', 'linkedin']} className=' w-6 h-6' />
                            </a>
                            <a className="mr-4 text-white dark:text-neutral-200" target='_blank' href={INSTA_LINK}>
                                <FontAwesomeIcon icon={['fab', 'instagram']} className=' w-6 h-6' />
                            </a>
                            <a className="mr-4 text-white dark:text-neutral-200" target='_blank' href={TWITTER_LINK}>
                                <FontAwesomeIcon icon={['fab', 'twitter']} className=' w-6 h-6' />
                            </a>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
}