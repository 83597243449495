import React, { useEffect } from 'react'
import ServicesSection from '../components/UserInterface/ServicesSection'
import Navbar from '../components/UserInterface/Navbar'

function Services() {
  
  useEffect(()=>{
    document.title="Softlyfy - Services"
      },[])
  return (
    <div className=''>
      <Navbar/>
      <ServicesSection/>
    </div>
  )
}

export default Services