import React, { useEffect } from 'react'
import ContactCard from '../components/UserInterface/ContactCard'
import Footer from '../components/UserInterface/Footer'
import Navbar from '../components/UserInterface/Navbar'

function ContactUs() {
  useEffect(() => {
    document.title = "Softlyfy - Contact"
  }, [])
  return (
    <div>

      <Navbar className="relative" />
      <ContactCard />
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default ContactUs