
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { ABOUT_PATH, CONTACT_PATH, CONTACT_US_PATH, HOME_PATH, LOGIN_PATH, PORTFOLIO_PATH, SERVICES_PATH, USER_PATH } from '../Utilities/Constants';
import Home from "../Screens/Home";
import Contact from "../Screens/Contact";
import Users from "../Screens/Users";
import NotFound from "../Screens/NotFoundPage";
import Navbar from "../components/UserInterface/Navbar";
import About from "../Screens/About";
import ContactUs from "../Screens/ContactUs";
import Services from "../Screens/Services";
import Portfolio from "../Screens/Portfolio";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faDatabase, faXmark, } from '@fortawesome/free-solid-svg-icons'
import { faBootstrap, faFacebook, faInstagram, faJs, faLinkedin, faPhp, fab } from '@fortawesome/free-brands-svg-icons';
import Login from "../Screens/Login";

library.add(fab, faXmark, faBars, faPhp, faJs, faDatabase, faBootstrap, faFacebook, faInstagram, faLinkedin,)
const AppRouter = () => {
  return (
    <div>
      <Router>

        <Navbar />
        <Routes>
          <Route path={LOGIN_PATH} element={<Login />} />
          <Route path={HOME_PATH} element={<Home />} />
          <Route path={USER_PATH} element={<Users />} />
          <Route path={ABOUT_PATH} element={<About />} />
          <Route path={CONTACT_US_PATH} element={<ContactUs />} />
          <Route path={SERVICES_PATH} element={<Services />} />
          {/* <Route path={PORTFOLIO_PATH} element={<Portfolio />} /> */}
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  )
}

export default AppRouter