import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

  const ParticleBackground = () => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

 
  const calculateParticlesVisibility = () => {
    const threshold = 200;
    const shouldShowParticles = window.scrollY < threshold;

    if (shouldShowParticles) {
      return {
        zIndex: -2,
        background: {
            color: {
                value: "#000000",
            },
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "attract",
                },
            },
            modes: {
                push: {
                    quantity: 10,
                },
                attract: {
                    distance: 800,
                    duration: 1,
                },
            },
        },
        particles: {
            color: {
                value: "#66c2ff",
            },
            links: {
                color: "#b3b3b3",
                distance: 150,
                enable: true,
                opacity: 0.8,
                width: 1,
            },
            move: {
                bounce: true, // Particles will bounce off the edges
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce", // Bounce off the canvas edges
                },
                random: false,
                speed: 3,
                straight: false,
            },
            number: {
                density: {
                    enable: true,
                },
                value: 150,
                max: 300,
            },
            opacity: {
                value: 0.9,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
            destroy: {
                mode: "split",
            },
        },
        detectRetina: true,
      };
    } else {
      // Return options when particles should be hidden
      return {
        display: "none",
      };
    }
  };
  const options = useMemo(() => calculateParticlesVisibility(), [init]);


  if (init) {
    return (
      <Particles
      className="relative"
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}

      />
    );
  }

  return <>
  <div ></div>
  </>;
};


export default ParticleBackground