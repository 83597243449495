import { Link } from "react-router-dom";
export default function ButtonContact({ text, onClick, color }) {
    return (
      <button
        onClick={onClick}
        className={`bg-${color}  rounded-full font-body text-base lg:text-xl md:text-base sm:text-base text-white py-2 px-6 md:px-4 sm:px-2 hidden md:hidden lg:block xl:block`}
      >
  
        {text}
     
       
      </button>
    );
  }
  