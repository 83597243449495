import axios from "axios"
import { BASE_URL } from "../Utilities/Constants"

const SubmitContact = (obj) => {
    return new Promise((resolve, reject) => {

        const request = axios.create({
            baseURL: BASE_URL,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })

        request.post("/new_contact", obj).then(res => {
            resolve({
                message: res.data.message,
                status: 200
            });
        }, error => {
            console.log(error.response);
            // Invalid credentials
            (error.response.status == 404 && error.code == 'ERR_BAD_REQUEST') && reject({
                status: error.response.status,
                message: error.response.data.message,
            });

            // too many request exception
            (error.response.status == 429 && error.code == 'ERR_BAD_REQUEST') && reject({
                status: error.response.status,
                message: error.response.data.message,
            });

            // when server try to validate request by validation failed either one of the filed empty or other validation condtion failed
            (error.response.status == 401 && error.code == 'ERR_BAD_REQUEST') && reject({
                status: error.response.status,
                message: "Please provide all the required details in the contact form before submitting.",
                data: error.response.data.errors
            });

            // when request made to server and server responde invalid URL 
            (error.response.status == 405 && error.code == 'ERR_BAD_REQUEST') && reject({
                status: error.response.status,
                message: 'Request Failed',
                data: {
                    message: error.response.data.message,
                    url: error.response.config.url
                }
            });
            reject({
                status: error.response.status,
                message: 'Something went wrong. Contact us at +92 322 2387936!'
            })
        })
    })
}



export { SubmitContact }